<template>
  <div class="about">
    <Header></Header>
    <section class="section4">
      <div class="title">团队介绍</div>
      <ul class="scientist">
        <li v-for="(item, index) in teamData" :key="index">
          <div class="left-part">
            <img :src="item.iconUrl" />
          </div>
          <div class="right-part">
            <div class="name">{{ item.name }}</div>
            <div class="position">{{ item.position }}</div>
            <div class="description">
              <div class="paragraph" v-for="(value, Index) in item.paragraph" :key="Index">
                <div><span></span></div>
                <div>{{ value }}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <tools></tools>
  </div>
</template>
<script>
import Tools from '@/components/tools'
import Header from '@/components/menu'
// import CompanyAwards from '@/components/company-awards/index'
export default {
  components: { Tools, Header },
  data() {
    return {
      detail_txt: `上海近屿智能科技有限公司的前身是南京葡萄诚信息科技有限公司，成立于2017年5月，她是一家以AI+RPA+BI技术为核心的人力资源方向的产品和解决方案提供商，拥有基于先进的篇章级语义识别算法和多模态算法构建的人力资源行业超脑-近屿超脑。近屿智能致力于通过先进技术，逐步的将人类从繁重、重复的劳动中解放出来，提升人类工作的效能。<br /><br />
        其核心产品AI得贤招聘官是一款智慧招聘管理系统，区别于传统的智能招聘ATS，AI得贤招聘官具有AI多模态算法核心，能够替代人类面试官对候选人的核心价值观，通用素质，潜在领导力和专业素质进行基于视频面试的判断，是一款L5级别AIGC面试系统。该系统已经被包括三星中国、招商银行、西门子等众多大客户验证可以完全替代人类面试官进行招聘面试场景的最终决策。<br /><br />
        AI得贤招聘官已上架至SAP APP Center ，在200个国家和地区，向SAP的全球客户提供AI招聘和面试科技解决方案。AI得贤招聘官也已登录钉钉开放平台，为3000多万家中小型客户提供千企千面的招聘自动化、AI视频面试、模拟编程、认知能力测试、职业性格测试、心理测评等服务。`,
      teamData: [
        {
          iconUrl: require('./images/ceo.png'),
          name: '方小雷',
          position: 'CEO',
          paragraph: [
            '南京大学工商管理学士，多伦多大学Rotman商学院MBA；',
            '中兴通讯人力资源总监，德国瑞好人力资源高级主管，五星电器、万科集团人力资源相关工作；',
            '11年人力资源行业从业者，曾在中国、德国、西班牙、英国和加拿大等地全面负责集团所在区域的人力资源管理，具有地产、零售、化学和通信等多个行业的管理经验；是人力资源劳动关系专家、薪酬专家和人才培养与发展专家。',
          ],
        },
        {
          iconUrl: require('./images/kx.png'),
          name: '陈清财',
          position: '合伙人',
          paragraph: [
            '哈工大（深圳）智能计算研究中心主任，教授、博导，中国中文信息学会理事；',
            '中文自然语言处理领域专家，具有20余年丰富算法和工程融合经验；',
            '曾任英特尔（中国）有限公司高级软件工程师，香港理工大学访问学者等职。',
          ],
        },
        {
          iconUrl: require('./images/ck.png'),
          name: '陈凯',
          position: 'AIGC大模型实验室 主任',
          paragraph: [
            '哈尔滨工业大学 人工智能博士。',
            '8年以上NLP研究经验，主要研究方向包括自然语言生成、文本摘要、机器阅读理解；对开放问答评估、对话系统构建、口音识别等具有丰富经验。',
            '在AIGC大模型领域有着丰富的研发经验和成果。负责公司自研AIGC HR行业大模型——近屿超脑的设计、增量预训练和微调等工作。能熟练运用各种技术手段构建性能卓越的AI大规模产品。',
          ],
        },
        // {
        //   iconUrl: require('./images/xlx.png'),
        //   name: '范津砚博士',
        //   position: '首席心理学家',
        //   paragraph: [
        //     '华东师范大学心理学学士（1994年）、硕士（1997年），美国俄亥俄州立大学工业与组织心理学博士。',
        //     '现任美国奥本大学心理系教授。主要研究领域是人工智能、人事选拔、新员工入职培训和社会化过程、和跨文化适应和培训。他在工业与组织心理学的专业杂志上（如Journal of Applied Psychology, Journal of Organizational Behavior, Journal of Management）发表了多篇论文。',
        //     '范博士曾担任重要学术期刊Journal of Vocational Behavior杂志的副主编（2019 – 2021）。',
        //   ],
        // },
        {
          iconUrl: require('./images/jg.png'),
          name: 'Dr.Laurence Lau',
          position: '首席架构师',
          paragraph: [
            '核心模型Talent-DNA发明人，德国卡尔蔡司IMT总经理，负责东南亚区域销售、客服、技术管理相关工作；荷兰飞利浦商务运营总监，负责VCD/DVD机芯的研发、生产、市场管理相关工作；德国瑞好大中华区以及亚洲区HR总监；',
            '具有全球七个国家超过20年的跨国管理经验，飞利浦、卡尔蔡司几乎所有高管职位的管理经验。',
          ],
        },
      ],
    }
  },
  created() {
    document.title = '团队介绍-AI得贤招聘官'
  },
}
</script>
<style lang="scss" scoped>
.about {
  overflow: hidden;
  .section1 {
    position: relative;
    // padding-top: 60px;
    .barnner {
      position: relative;
      max-height: 240px;
      overflow: hidden;
      .banner-img {
        display: block;
        width: 100%;
        height: auto;
      }
      .text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 240px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          display: block;
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          line-height: 34px;
          letter-spacing: 3px;
        }
      }
    }
  }
  .section2 {
    padding: 40px 25px 50px;
    .title {
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
      margin-bottom: 25px;
      text-align: center;
    }
    p {
      margin: 0;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #627098;
      line-height: 22px;
    }
  }
  .section3 {
    // padding: 50px 25px;
    // background: #f2f4f8;
    // .title {
    //   font-size: 15px;
    //   font-family: PingFangSC-Semibold, PingFang SC;
    //   font-weight: 600;
    //   color: #333333;
    //   line-height: 21px;
    //   text-align: center;
    // }
    // .history {
    //   margin-top: 26px;
    //   height: 586px;
    // }
  }
  .section4 {
    padding: 50px 25px;
    // padding-top: 60px;
    .title {
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    .scientist {
      margin-top: 25px;
      li {
        margin-bottom: 20px;
        box-shadow: 0px 0px 8px 0px rgba(13, 20, 50, 0.2);
        border-radius: 4px;
        position: relative;
        &:last-child {
          margin: 0;
        }
        .left-part {
          position: absolute;
          left: 15px;
          top: 20px;
          img {
            display: block;
            width: 42px;
            height: 42px;
          }
        }
        .right-part {
          padding: 20px 15px;
          .name {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 20px;
            margin-left: 50px;
            margin-top: 3px;
          }
          .position {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 17px;
            margin-left: 50px;
          }
          .description {
            margin-top: 13px;
            .paragraph {
              margin-bottom: 10px;
              display: flex;
              div:first-of-type {
                margin-right: 8px;
                text-align: center;
                span {
                  display: inline-block;
                  width: 2px;
                  height: 2px;
                  border-radius: 1px;
                  background: #627098;
                  // transform: translateY(-2px);
                }
              }
              div:last-of-type {
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
